
.mce-content-body  {
    font-size:var(--base-font-size);
    background:var(--secondary-background);
    max-width:760px;
    text-align:left;
}

.mce-content-body.centered {
    margin:auto;
}

.viewDescription.mce-content-body {
    margin:0px;
}

.commentContent .mce-content-body {
    background: var(--col-title-bg);
}


.tox .tox-tbtn svg {
    display: block;
    fill: var(--primary-font-color);
}

.tox .tox-dialog,
.tox .tox-dialog .tox-dialog__title,
.tox .tox-dialog .tox-button{
    font-family: var(--primary-font-family);
}

.tox .tox-dialog .tox-button {
    font-weight:normal;
    background:var(--main-action-bg);
    border:0px;
    font-weight: bold;
}

.tox .tox-dialog .tox-button--secondary {
    background:var(--col-title-bg);
    color:var(--kanban-col-title-color);
}

.tox .tox-dialog .tox-button--naked {
    background:transparent;
}

.tox .tox-dialog {
    border:0px;
}

.tox .tox-form .tox-selectfield select,
.tox .tox-form .tox-textarea,
.tox .tox-form .tox-textfield,
.tox .tox-form .tox-toolbar-textfield,
.tox .tox-form .tox-label,
.tox .tox-form .tox-toolbar-label,
.tox .tox-form :not(svg)
{
    font-size:var(--base-font-size);
    font-family: var(--primary-font-family);
    color:var(--primary-font-color);
}

body .tox-tinymce {
    border-radius:3px;
    z-index: 8;
}

body .tox .tox-toolbar,
body .tox .tox-toolbar__overflow,
body .tox .tox-toolbar__primary,
body .tox .tox-edit-area{
    border-top:0px;
}

body .tox .tox-toolbar__primary {
    background:var(--col-title-bg);
    border-radius:20px;
}

.tox-statusbar {
    display:none;
}

body .tox-tinymce {
    border:0px;
}

body .tox-editor-header {
    border:0px solid var(--main-border-color) !important;
}

body .tox-editor-container>:first-child{
    border-bottom:0px solid var(--main-border-color) !important;
}

body .tox-edit-area {
    cursor: text;

}

body .tox .tox-mbtn,
body .tox .tox-collection__item-label {
    font-size:var(--base-font-size);
    font-family: var(--primary-font-family);
}

body .tox .tox-mbtn--active {
    background:var(--secondary-action-bg);
}

.tox-checklist>li:not(.tox-checklist--hidden) {
    list-style: none;
    margin: .25em 0;
}
.tox-checklist>li:not(.tox-checklist--hidden)::before {
    background-image:url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-unchecked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2215%22%20height%3D%2215%22%20x%3D%22.5%22%20y%3D%22.5%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%234C4C4C%22%20rx%3D%222%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
    background-size:100%;
    content:'';
    cursor:pointer;
    height:1em;
    margin-left:-1.5em;
    margin-top:.125em;
    position:absolute;
    width:1em;
}
.tox-checklist li:not(.tox-checklist--hidden).tox-checklist--checked::before {
    background-image:url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-checked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%234099FF%22%20fill-rule%3D%22nonzero%22%20rx%3D%222%22%2F%3E%3Cpath%20id%3D%22Path%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20d%3D%22M11.5703186%2C3.14417309%20C11.8516238%2C2.73724603%2012.4164781%2C2.62829933%2012.83558%2C2.89774797%20C13.260121%2C3.17069355%2013.3759736%2C3.72932262%2013.0909105%2C4.14168582%20L7.7580587%2C11.8560195%20C7.43776896%2C12.3193404%206.76483983%2C12.3852142%206.35607322%2C11.9948725%20L3.02491697%2C8.8138662%20C2.66090143%2C8.46625845%202.65798871%2C7.89594698%203.01850234%2C7.54483354%20C3.373942%2C7.19866177%203.94940006%2C7.19592841%204.30829608%2C7.5386474%20L6.85276923%2C9.9684299%20L11.5703186%2C3.14417309%20Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A")
}

.tox-checklist {
    margin-left: 22px;
}

body.mce-content-body {
    padding:14px !important;
}

.mce-content-body {
    font-family: var(--primary-font-family);
}

.mce-content-body h1,
.mce-content-body h2,
.mce-content-body h3,
.mce-content-body h4,
.mce-content-body h5 {
    font-weight: bold;
    line-height: normal;
    color:var(--primary-color);
    margin-top:25px;
}


.mce-content-body  h1 { font-size:var(--font-size-xxl); padding:5px 0px 10px 0px;}
.mce-content-body  h2 { font-size:var(--font-size-xl); padding:5px 0px 10px 0px;}
.mce-content-body  h3 { font-size:var(--font-size-l); padding:5px 0px 10px 0px;}
.mce-content-body  h4 { font-size:var(--base-font-size); font-weight:bold; padding:5px 0px 5px 0px;}
.mce-content-body  h5 { font-size:var(--base-font-size); font-weight:bold; padding:5px 0px 5px 0px;}

.mce-content-body  ol,
.mce-content-body  ul {
    margin-left:15px;
    margin-bottom:15px;
}

.mce-content-body table {
    border:1px solid var(--neutral);
    border-top-left-radius:var(--box-radius);
    border-top-right-radius:var(--box-radius);
    border-collapse: initial !important;
    border-spacing: 0px;

}



.mce-content-body table thead td,
.mce-content-body table th,
.mce-content-body table td {
    text-rendering: optimizelegibility;
    border:0px;
    border-top:1px solid var(--neutral);
    box-shadow: 0px 1px 1px var(--neutral);
    border-right:1px solid var(--neutral);
    line-height: 17px;
    border:0px;
    color: var(--kanban-col-title-color);
    background:transparent;
    padding: 5px 10px 3px 10px;
    vertical-align: middle;
    min-height: 17px;
}

.mce-content-body table thead td {

    padding: 7px 10px 5px 10px;
    font-weight: bold;
    text-transform:none;
    font-size: var(--base-font-size);
    background:var(--col-title-bg);
}

.mce-content-body table tr:hover {
    box-shadow:var(--regular-shadow);
}

.mce-content-body table tr:hover td {
    background:var(--col-title-bg);

}

.mce-content-body thead tr:first-child td:first-child {
    border-top-left-radius:var(--box-radius);
}

.mce-content-body thead tr:last-child td:last-child {
    border-top-right-radius:var(--box-radius);
}

.mce-content-body  p {
    margin-top: 0px;
    margin-bottom:15px;
}

.mce-content-body  th:last-child {
    border-right:0px;
}

.userMention {
    border-radius: 10px;
    text-decoration: none;
    color: var(--primary-color);
    font-weight:bold;
    white-space: nowrap;
}

.userMention img {
    width: 17px;

    border-radius: 10px;
    vertical-align: text-bottom;
    margin-right: 3px;
}

a.userMention {
    color: var(--primary-color);
    font-weight:bold;
}

a.userMention:hover,
a.userMention:focus {
    text-decoration: none;
}

a.confetti {
    cursor:pointer;
    color:var(--primary-color);
    font-weight:bold;
}

#tinyPlaceholder {
    opacity:0.7;

}

.tox .tox-dialog,
.tox .tox-dialog__header,
.tox .tox-selectfield select,
.tox .tox-textarea,
.tox .tox-textfield,
.tox .tox-toolbar-textfield,
.tox .tox-dialog__footer{
    background-color:var(--secondary-background) !important;
    color:var(--primary-font-color) !important;
    border-color:var(--main-border-color) !important;
}

.tox .tox-dialog__body-nav-item--active {
    color:var(--primary-color) !important;
}

.tox .tox-dialog__body-nav-item,
.tox .tox-dropzone p,
.tox .tox-button--naked{
    color:var(--primary-font-color) !important;
}

.tox .tox-button--naked:hover:not(:disabled) {
    color:var(--primary-font-color) !important;
    background-color:var(--primary-background) !important;
}

.tox .tox-dialog__footer {
    border-top: 1px solid var(--main-border-color);
}

.tox-dropzone {
    background:var(--primary-background) !important;
}


.mirrorElement {
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
    display: Inline-block;
}

.tox .tox-form .tox-collection__item-icon {
    font-size:var(--font-size-xxl);
}

body .tox .tox-dialog-wrap__backdrop {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

:not(pre)>code[class*=language-], pre[class*=language-] {
    background: #f5f6f7;
}

.maincontentinner .tinyPlaceholder,
.commentContent .tinyPlaceholder{
    display:none;
}

.mce-content-body ul {
    padding-inline-start: 10px;
}


body.mce-content-body .row .col-md-4,
body.mce-content-body .row .col-md-2,
body.mce-content-body .row .col-md-3,
body.mce-content-body .row .col-md-4,
body.mce-content-body .row .col-md-5,
body.mce-content-body .row .col-md-6,
body.mce-content-body .row .col-md-7,
body.mce-content-body .row .col-md-8,
body.mce-content-body .row .col-md-9,
body.mce-content-body .row .col-md-10,
body.mce-content-body .row .col-md-11,
body.mce-content-body .row .col-md-12 {
    border: 1px dotted var(--neutral) !important;
}

.mce-content-body label.label-important,
.mce-content-body label.label-warning,
.mce-content-body label.label-success,
.mce-content-body label.label-info,
.mce-content-body label.label-inverse,
.mce-content-body label.label-default,
.mce-content-body label.label-primary,
.mce-content-body label.label-danger {
 display:inline-block;
    cursor:default;
}

.mce-content-body label.label-default {
    color:var(--primary-font-color);
}

.tox .tox-form.advTemplates {
    display:block;
    height:100%;
}

.advTemplates > .l {
    float:left;
    width:25%;

}

.advTemplates > .l .tox-label,
.advTemplates > .l .tox-listboxfield {
    display:inline-block;
}

.advTemplates > .l .tox-listboxfield {
    float:right;
}

.advTemplates > .l > .tox-form__group {
    padding:5px;
}

.advTemplates > .l .tox-form__group:first-child {
    border:1px solid var(--secondary-background);
}

.advTemplates > .l .tox-form__group:first-child:hover {
    border:1px solid var(--neutral);
}

.advTemplates > .l .tox-form__group {
    margin-top:15px;
    font-style: italic;
}

.advTemplates > .l .bordered {
    border-top:1px dotted var(--neutral);
    padding-top:15px;
}

.advTemplates > .r {
    float:right;
    width:72%;
    height:100%;
}

.advTemplates > .r .tox-form__group {
    height:100%;
}
.advTemplates > .r .tox-label {
    margin-top:15px;
    margin-bottom:10px;
    font-size:var(--font-size-xl);
    font-weight:bold;
}
.advTemplates > .r .tox-navobj {
    border:1px dotted var(--neutral);
    height:100%;
}

.tox .tox-dialog {
    border-radius:var(--box-radius-large) !important;
}

.loading-text p {
    color: transparent;
    height: 16px;
    width: 100%;
    background: linear-gradient(100deg, var(--col-title-bg) 30%, var(--neutral) 50%, var(--col-title-bg)  70%);
    background-size: 400% 100%;
    animation: loadingTextAnimation 1.2s ease-in-out infinite;
    margin-bottom: 10px;
    border-radius: var(--box-radius);
}

@keyframes loadingTextAnimation {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
